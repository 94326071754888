import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { StateService } from 'clocker/shared/services';
import { VerifyService } from './service/verify.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, OnDestroy {
  public readonly verify = {
    service: inject(VerifyService),
  };

  public readonly shared = {
    state: inject(StateService),
  };

  ngOnInit(): void {
    this.verify.service.checkSuccess();
  }

  ngOnDestroy(): void {
    this.verify.service.clockStatus.set(null);
  }
}
