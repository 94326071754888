import { Injectable, computed, signal } from '@angular/core';
import { storage, State } from 'clocker/shared/utils';

const model = {
  id: storage.get('id', ''),
  name: storage.get('name', ''),
  supplier: storage.get('supplier', ''),
  shift_id: storage.get('shift_id', ''),
  start: storage.get('start', true),
  night: storage.get('night', false),
  out: storage.get('out', false),
  deployment_zone: storage.get('deployment_zone', ''),
  notes: storage.get('notes', ''),
  position: storage.get('position', ''),
  start_time: storage.get('start_time', ''),
  end_time: storage.get('end_time', ''),
};

export type ShiftModel = typeof model;

export type QrModel = {
  staff_id: number;
  staff_type: string;
};

@Injectable({
  providedIn: 'root',
})
export class ShiftState extends State<ShiftModel> {
  public readonly id = signal(model.id);
  public readonly name = signal(model.name);
  public readonly supplier = signal(model.supplier);
  public readonly shift_id = signal(model.shift_id);
  public readonly start = signal(model.start);
  public readonly night = signal(model.night);
  public readonly out = signal(model.out);
  public readonly deployment_zone = signal(model.deployment_zone);
  public readonly position = signal(model.position);
  public readonly start_time = signal(model.start_time);
  public readonly end_time = signal(model.end_time);
  public readonly notes = signal(model.notes);

  public readonly model = computed(() => {
    return {
      id: this.id(),
      name: this.name(),
      supplier: this.supplier(),
      shift_id: this.shift_id(),
      start: this.start(),
      night: this.night(),
      out: this.out(),
      notes: this.notes(),
      deployment_zone: this.deployment_zone(),
      position: this.position(),
      start_time: this.start_time(),
      end_time: this.end_time(),
    };
  });

  constructor() {
    super({ model, storage: true });
  }
}
