@if (verify.clockStatus() === 'OUT') {
  <div class="my-10 text-xl">You have successfully clocked out</div>
} @else {
  <div class="location">
    <div class="mb-5">
      <label>Zone</label>

      <p>
        {{ shared.state.shift.deployment_zone() || 'N/A' }}
      </p>
    </div>

    <div>
      <label>Position</label>

      <p>{{ shared.state.shift.position() || 'N/A' }}</p>
    </div>
  </div>
}

<div class="location">
  <div>
    <label
      >Clocker {{ verify.clockStatus() === 'OUT' ? 'Out' : 'In' }} Notes</label
    >

    <p>{{ shared.state.shift.notes() }}</p>
  </div>
</div>

<button
  class="action bg-success !py-4 !text-xl md:!text-2xl"
  (click)="router.navigateByUrl('/pin')"
>
  CONTINUE... {{ countdown$ | async }}
</button>
