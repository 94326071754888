import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, startWith, take, timer } from 'rxjs';
import { VerifyService } from '../../service/verify.service';
import { ShiftService, StateService } from 'clocker/shared/services';

@Component({
  selector: 'app-verify-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['../../verify.component.scss'],
})
export class VerifyChoiceComponent implements OnInit {
  private router = inject(Router);

  public readonly verify = {
    service: inject(VerifyService),
  };

  public readonly shared = {
    state: inject(StateService),
    shift: inject(ShiftService),
  };
  public countdown$!: Observable<number>;

  ngOnInit() {
    const start = 3;

    this.countdown$ = timer(0, 1000).pipe(
      map((second) => {
        if (start - second === 0) this.router.navigateByUrl('/capture');
        return start - second;
      }),
      take(start + 1),
      startWith(start),
    );
  }
}
