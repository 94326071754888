import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'clocker/shared/services';
import { Observable, map, startWith, take, timer } from 'rxjs';
import { VerifyService } from '../../service/verify.service';

@Component({
  selector: 'app-verify-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['../../verify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyConfirmComponent implements OnInit {
  public router = inject(Router);
  public readonly verify = inject(VerifyService);

  public readonly shared = {
    state: inject(StateService),
  };

  public countdown$!: Observable<number>;

  ngOnInit() {
    const start = 30;

    this.countdown$ = timer(0, 1000).pipe(
      map((second) => {
        if (start - second === 0) this.router.navigateByUrl('/pin');
        return start - second;
      }),
      take(start + 1),
      startWith(start),
    );
  }
}
